<template>
  <div class="w-100">
    <b-overlay :show="loading">
      <div class="p-6 coach-profile-detail w-100">
        <div class="coach-profile-left">
          <b-card class="h-100">
            <b-row>
              <b-col>
                <div class="info">
                  <avatar
                    size="100px"
                    :text="coachDetail.name"
                    rounded
                    :src="coachDetail.image"
                  />
                  <div>
                    <h5 class="text-overflow ellipsis">
                      {{ coachDetail.name || '------' }}
                    </h5>
                    <div class="d-flex">
                      <b-button
                        v-if="isWriteAllPermission || isWriteOwnerPermission"
                        size="sm"
                        class="btn-edit mr-5"
                        @click="goToEdit"
                      >
                        <span class="svg-icon svg-icon-sm mr-0">
                          <inline-svg
                            class="svg-icon mr-0"
                            src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                          />
                        </span>
                      </b-button>
                      <b-button
                        pill
                        size="sm"
                        class="btn-status px-7"
                        :class="getStatus(coachDetail.status)"
                        >{{ getStatus(coachDetail.status) }}
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col>
                <b-row>
                  <b-col>
                    <label class="text-gray">Tên đăng nhập:</label>
                    <div>{{ coachDetail.username || '------' }}</div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="introduction">
                      <span
                        id="content-introduction"
                        ref="content"
                        class="text"
                      >
                        {{
                          isSeeMore
                            ? coachDetail.introduction
                            : convertText(coachDetail.introduction)
                        }}
                      </span>
                      <template v-if="isDisplaySeeMore">
                        <span
                          v-if="!isSeeMore"
                          class="see__more"
                          @click.stop="seeMore"
                          >See more</span
                        >
                        <span v-else class="see__less" @click.stop="seeMore"
                          >See less</span
                        >
                      </template>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <label class="text-gray">Ngày sinh:</label>
                <div>{{ coachDetail.brithday || '------' }}</div>
              </b-col>
              <b-col cols="6">
                <label class="text-gray">Giới tính:</label>
                <div>{{ getTextDropdown(coachDetail.sex) || '------' }}</div>
              </b-col>
              <b-col cols="6">
                <label class="text-gray">Số điện thoại 1:</label>
                <div>{{ coachDetail.firstPhoneNumber || '------' }}</div>
              </b-col>
              <b-col cols="6">
                <label class="text-gray">Số điện thoại 2:</label>
                <div>{{ coachDetail.secondPhoneNumber || '------' }}</div>
              </b-col>
              <b-col cols="6">
                <label class="text-gray">Facebook:</label>
                <div>{{ coachDetail.facebookUrl || '------' }}</div>
              </b-col>
              <b-col cols="6">
                <label class="text-gray">Email:</label>
                <div>{{ coachDetail.email || '------' }}</div>
              </b-col>
              <b-col cols="6">
                <label class="text-gray">URL:</label>
                <div>{{ coachDetail.url || '------' }}</div>
              </b-col>
              <b-col cols="6">
                <label class="text-gray">Vị trí:</label>
                <div>
                  {{ getTextDropdown(coachDetail.position) || '------' }}
                </div>
              </b-col>
              <b-col cols="6">
                <label class="text-gray">Quốc gia:</label>
                <div>{{ coachDetail.country || '------' }}</div>
              </b-col>
              <b-col cols="6">
                <label class="text-gray">Tỉnh thành:</label>
                <div>{{ coachDetail.province || '------' }}</div>
              </b-col>
              <b-col cols="6">
                <label class="text-gray">Quận / Huyện:</label>
                <div>{{ coachDetail.district || '------' }}</div>
              </b-col>
              <b-col cols="6">
                <label class="text-gray">Phường / Xã:</label>
                <div>{{ coachDetail.ward || '------' }}</div>
              </b-col>
              <b-col cols="6">
                <label class="text-gray">Địa chỉ:</label>
                <div>{{ coachDetail.address || '------' }}</div>
              </b-col>
              <b-col cols="6">
                <label class="text-gray">Vai trò:</label>
                <div>
                  {{ getTextMultipleSelect(coachDetail.role) || '------' }}
                </div>
              </b-col>
              <b-col cols="6">
                <label class="text-gray">Agency:</label>
                <div>{{ getTextDropdown(coachDetail.agency) || '------' }}</div>
              </b-col>
              <template v-if="showAccountRules">
                <b-col cols="6">
                  <label class="text-gray">Học vấn:</label>
                  <div>{{ coachDetail.qualification || '------' }}</div>
                </b-col>
                <b-col cols="6">
                  <label class="text-gray">Phân cấp:</label>
                  <div>
                    {{ getTextDropdown(coachDetail.level) || '------' }}
                  </div>
                </b-col>
                <b-col cols="6">
                  <label class="text-gray">Phân loại:</label>
                  <div>
                    {{ getTextDropdown(coachDetail.typeOfWork) || '------' }}
                  </div>
                </b-col>
              </template>
              <b-col v-if="showManagementCoachs">
                <label class="text-gray">Coach manager:</label>
                <div>
                  {{ getTextDropdown(coachDetail.coachManager) || '------' }}
                </div>
              </b-col>
              <b-col cols="6">
                <label class="text-gray">Mã giới thiệu:</label>
                <div>{{ coachDetail.referralCode || '------' }}</div>
              </b-col>
            </b-row>
          </b-card>
        </div>
        <div class="coach-profile-right">
          <b-card v-if="showAccountRules">
            <h5>Đánh giá tính cách và tiêu chí chọn học viên</h5>
            <b-row>
              <b-col>
                <label class="text-gray">Độ tuổi:</label>
                <div>
                  {{ coachDetail.startAge || '------' }} -
                  {{ coachDetail.endAge || '------' }}
                </div>
              </b-col>
              <b-col>
                <label class="text-gray">Giới tính:</label>
                <div>
                  {{
                    getTextMultipleSelect(coachDetail.selectedSexes) || '------'
                  }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="text-gray">Số lượng học viên:</label>
                <div>{{ coachDetail.memberQuantity || '------' }}</div>
              </b-col>
              <b-col>
                <label class="text-gray">Tính cách:</label>
                <div>
                  {{
                    getTextMultipleSelect(coachDetail.selectedPersonalities) ||
                    '------'
                  }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="text-gray">Sở thích cá nhân:</label>
                <div>
                  {{
                    getTextMultipleSelect(coachDetail.selectedInterests) ||
                    '------'
                  }}
                </div>
              </b-col>
              <b-col>
                <label class="text-gray">Môn thể thao yêu thích:</label>
                <div>
                  {{
                    getTextMultipleSelect(coachDetail.selectedSports) ||
                    '------'
                  }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="text-gray">Thực hành tâm thức:</label>
                <div>
                  {{
                    getTextMultipleSelect(
                      coachDetail.selectedConsciousnesses,
                    ) || '------'
                  }}
                </div>
              </b-col>
              <b-col>
                <label class="text-gray">Tính địa phương:</label>
                <div>
                  {{
                    getTextMultipleSelect(coachDetail.selectedRegions) ||
                    '------'
                  }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="text-gray">Ngôn ngữ:</label>
                <div>
                  {{
                    getTextMultipleSelect(coachDetail.selectedLanguages) ||
                    '------'
                  }}
                </div>
              </b-col>
              <b-col>
                <label class="text-gray">Tôn giáo:</label>
                <div>
                  {{
                    getTextMultipleSelect(coachDetail.selectedReligions) ||
                    '------'
                  }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="text-gray">Ăn chay:</label>
                <div>
                  {{
                    getTextMultipleSelect(coachDetail.selectedVegetarians) ||
                    '------'
                  }}
                </div>
              </b-col>
              <b-col>
                <label class="text-gray">Mức độ đái tháo đường:</label>
                <div>
                  {{
                    getTextMultipleSelect(coachDetail.selectedDiabetesLevels) ||
                    '------'
                  }}
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label class="text-gray">Khung giờ làm việc:</label>
                <div>
                  {{
                    getTextMultipleSelect(coachDetail.selectedWorkingTimes) ||
                    '------'
                  }}
                </div>
              </b-col>
              <b-col>
                <label class="text-gray">Kinh nghiệm chuyên sâu:</label>
                <div>
                  {{
                    getTextMultipleSelect(coachDetail.selectedExperiences) ||
                    '------'
                  }}
                </div>
              </b-col>
            </b-row>
          </b-card>
          <b-card>
            <h5>QR Code</h5>
            <b-row>
              <b-col cols="6">
                <div class="text-center">
                  <h6>Chia sẻ hồ sơ</h6>
                  <canvas
                    id="qr-code"
                    ref="qrcodeCanvas"
                    class="share-qrcode__canvas"
                  ></canvas>
                </div>
                <div class="text-center mt-6">
                  <b-button
                    class="btn btn-success"
                    type="button"
                    @click="downloadQrCode"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/download.svg"
                      />
                    </span>
                    Download QR code (.PNG)
                  </b-button>
                </div>
              </b-col>
              <b-col cols="6">
                <div class="text-center">
                  <h6>Chia sẻ DiaB APP</h6>
                  <canvas
                    id="qr-code-DynamicLinks"
                    ref="qrcodeDynamicLinks"
                    class="share-qrcode__canvas"
                  ></canvas>
                </div>
                <div class="text-center mt-6">
                  <b-button
                    class="btn btn-success"
                    type="button"
                    @click="downloadQrCodeDynamicLinks"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/download.svg"
                      />
                    </span>
                    Download QR code (.PNG)
                  </b-button>
                </div>
                <div class="text-center mt-6">
                  <b-button
                    class="btn btn-border-radius"
                    type="button"
                    @click="copyRefCode"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/share.svg"
                      />
                    </span>
                    Copy link
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </div>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import QRCode from 'qrcode';
export default {
  name: 'CoachProfileDetail',
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      coachDetail: {
        status: false,
        code: '',
        name: '',
        username: '',
        brithday: '',
        sex: '',
        introduction: '',
        firstPhoneNumber: '',
        secondPhoneNumber: null,
        facebookUrl: '',
        email: '',
        url: null,
        position: '',
        country: '',
        province: '',
        district: '',
        ward: '',
        address: '',
        role: '',
        agency: '',
        qualification: '',
        classify: '',
        level: null,
        referralCode: null,
        typeOfWork: null,
        referalCode: '',
        image: null,
        coachManager: null,
        startAge: '',
        endAge: '',
        selectedSexes: [],
        memberQuantity: '',
        selectedPersonalities: [],
        selectedInterests: [],
        selectedSports: [],
        selectedConsciousnesses: [],
        selectedRegions: [],
        selectedReligions: [],
        selectedLanguages: [],
        selectedVegetarians: [],
        selectedWorkingTimes: [],
        selectedDiabetesLevels: [],
        selectedExperiences: [],
      },
      loading: false,
      isSeeMore: false,
    };
  },
  computed: {
    showAccountRules() {
      if (
        this.coachDetail.position &&
        ['Coach', 'Head Coach', 'Coach Manager'].includes(
          this.coachDetail.position.text,
        )
      )
        return true;
      return false;
    },
    showManagementCoachs() {
      if (
        this.coachDetail.position &&
        this.coachDetail.position.text === 'Coach'
      )
        return true;
      return false;
    },
    isWriteAllPermission() {
      return this.$route.meta.isWriteAllPermission;
    },
    isWriteOwnerPermission() {
      return this.$route.meta.isWriteOwnerPermission;
    },
    isDisplaySeeMore() {
      return (
        this.coachDetail.introduction &&
        this.coachDetail.introduction.length > 50
      );
    },
  },
  created() {
    this.getCoachDetail();
  },
  methods: {
    async getCoachDetail() {
      try {
        this.loading = true;
        const { meta, data, error } = await this.$api.get(
          '/Admin/Account/portal/PrepareCreateUpdateFormItem',
          {
            params: {
              id: this.id,
            },
          },
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
            preventDuplicates: true,
          });
        }
        const {
          active,
          code,
          fullName,
          username,
          introduction,
          dateOfBirth,
          phoneNumber,
          secondPhoneNumber,
          facebookUrl,
          email,
          url,
          nation,
          province,
          district,
          ward,
          address,
          accountRule,
          depthExperienceRuleList,
          levelOfDiabetesRuleList,
          workingHourRuleList,
          vegetarianRuleList,
          religionRuleList,
          languageRuleList,
          locallyRuleList,
          consciousnessPracticeRuleList,
          favouriteSportRuleList,
          interestRuleList,
          personalityRuleList,
          genderRuleList,
          levelList,
          typeOfWorkList,
          roleList,
          agencyList,
          genderList,
          positionList,
          education,
          referralCode,
          avatar,
          managerList,
        } = data;

        this.coachDetail.code = code;
        this.coachDetail.status = active;
        this.coachDetail.username = username;
        this.coachDetail.name = fullName;
        this.coachDetail.brithday = this.convertTimestampToDate(dateOfBirth);
        this.coachDetail.introduction = introduction;
        this.coachDetail.firstPhoneNumber = phoneNumber;
        this.coachDetail.secondPhoneNumber = secondPhoneNumber;
        this.coachDetail.facebookUrl = facebookUrl;
        this.coachDetail.email = email;
        this.coachDetail.url = url;
        this.coachDetail.address = address;
        this.coachDetail.qualification = education;
        this.coachDetail.referralCode = referralCode;
        this.coachDetail.image = avatar?.url;

        const dynamicLinks = await this.$api.getFirebaseDynamicLinks({
          longDynamicLink: `https://click.diab.com.vn/referralCode?link=https://diab.com.vn/referralCode=${referralCode}&ofl=https://diab.com.vn/giai-phap&apn=com.vbhc.diab&ibi=com.cactusoftware.diab&isi=1569353448&sd=Ứng dụng hoàn toàn miễn phí giúp kiểm soát bệnh đái tháo đường và kết nối với chuyên gia.&si=https://diab.com.vn/wp-content/uploads/2022/02/hinh-1-banner-trang-chu.png`,
        });
        this.coachDetail.referralCodeDynamicLinks = dynamicLinks;

        // value dropdown
        this.coachDetail.sex = genderList.find((el) => el.selected);
        this.coachDetail.position = positionList
          .map((el, i) => ({ ...el, type: i + 1 }))
          .find((el) => el.selected);
        this.coachDetail.agency = agencyList.find((el) => el.selected);
        this.coachDetail.role = roleList.filter((el) => el.selected);
        this.coachDetail.coachManager = managerList.find((el) => el.selected);

        // The position is coach, head coach, coach manager
        if (this.showAccountRules) {
          // account infomations
          this.coachDetail.level = levelList.find((el) => el.selected);
          this.coachDetail.typeOfWork = typeOfWorkList.find(
            (el) => el.selected,
          );

          // account rules
          this.coachDetail.startAge = accountRule?.fromAge;
          this.coachDetail.endAge = accountRule?.toAge;
          this.coachDetail.memberQuantity = accountRule?.amount;
          this.coachDetail.selectedSexes = genderRuleList.filter(
            (el) => el.selected,
          );
          this.coachDetail.selectedPersonalities = personalityRuleList.filter(
            (el) => el.selected,
          );
          this.coachDetail.selectedInterests = interestRuleList.filter(
            (el) => el.selected,
          );
          this.coachDetail.selectedSports = favouriteSportRuleList.filter(
            (el) => el.selected,
          );
          this.coachDetail.selectedConsciousnesses = consciousnessPracticeRuleList.filter(
            (el) => el.selected,
          );
          this.coachDetail.selectedRegions = locallyRuleList.filter(
            (el) => el.selected,
          );
          this.coachDetail.selectedLanguages = languageRuleList.filter(
            (el) => el.selected,
          );
          this.coachDetail.selectedReligions = religionRuleList.filter(
            (el) => el.selected,
          );
          this.coachDetail.selectedVegetarians = vegetarianRuleList.filter(
            (el) => el.selected,
          );
          this.coachDetail.selectedWorkingTimes = workingHourRuleList.filter(
            (el) => el.selected,
          );
          this.coachDetail.selectedDiabetesLevels = levelOfDiabetesRuleList.filter(
            (el) => el.selected,
          );
          this.coachDetail.selectedExperiences = depthExperienceRuleList.filter(
            (el) => el.selected,
          );
        }

        this.coachDetail.country = nation;
        this.coachDetail.province = province;
        this.coachDetail.district = district;
        this.coachDetail.ward = ward;

        this.generateQrCode();
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      } finally {
        this.loading = false;
      }
    },
    generateQrCode() {
      if (!this.coachDetail.referralCode) return;
      const options = {
        width: 200,
        height: 200,
      };

      const callback = (error) => error && console.error('QR CODE:', error);
      const value = `https://diab.com.vn/?${this.coachDetail.referralCode}`;
      const valueDynamicLinks = this.coachDetail.referralCodeDynamicLinks;
      QRCode.toCanvas(this.$refs.qrcodeCanvas, value, options, callback);
      QRCode.toCanvas(
        this.$refs.qrcodeDynamicLinks,
        valueDynamicLinks,
        options,
        callback,
      );
    },
    goToEdit() {
      this.$router.push({
        name: 'coach_profile_edit',
        params: {
          id: this.id,
        },
      });
    },
    getStatus(status) {
      if (status) return 'active';
      return 'inactive';
    },
    downloadQrCode() {
      let link = document.createElement('a');
      link.download = 'qr-code.png';
      link.href = document.querySelector('#qr-code').toDataURL('image/png');
      link.click();
    },
    downloadQrCodeDynamicLinks() {
      let link = document.createElement('a');
      link.download = 'qr-code-share-diab-app.png';
      link.href = document
        .querySelector('#qr-code-DynamicLinks')
        .toDataURL('image/png');
      link.click();
    },
    copyRefCode() {
      navigator.clipboard.writeText(
        'DiaB hướng dẫn chế độ dinh dưỡng, vận động, nghỉ ngơi cho người đái tháo đường\n' +
          this.coachDetail.referralCodeDynamicLinks,
      );
      this.$toastr.s({
        title: 'Thành công!',
        msg: 'Đã copy đường dẫn chia sẻ',
      });
    },
    getTextDropdown(object) {
      return object?.text || null;
    },
    getTextMultipleSelect(array) {
      if (!array || !array.length) return null;
      return array.map((el) => el.text).join(', ');
    },
    seeMore() {
      this.isSeeMore = !this.isSeeMore;
    },
    convertText(text) {
      if (!text) return null;
      if (text.length > 50) {
        return `${text.substr(0, 50)}...`;
      }
      return text;
    },
  },
};
</script>
<style lang="scss" scoped>
.coach-profile-detail {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  column-gap: 16px;
}

.coach-profile-right {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  row-gap: 16px;
}

.text-gray {
  color: #b5b5c3;
}
</style>

<style lang="scss">
.btn-edit {
  background-color: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}

.btn-status {
  color: #fff !important;
  text-transform: capitalize;
  position: relative;

  &.active {
    background-color: #21a567 !important;
    border-color: #21a567 !important;
  }

  &.inactive {
    background-color: #ff5756 !important;
    border-color: #ff5756 !important;
  }
}

.text {
  line-height: 21px;
  overflow: hidden;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-overflow {
  overflow: hidden;
}

.info {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  column-gap: 25px;
}

.see__more,
.see__less {
  font-weight: 500;
  font-size: 14px;
  color: blue;
  cursor: pointer;
}
</style>
